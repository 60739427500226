<script setup lang="ts">
import ImageUtil from '~/util/image-util';

const props = defineProps({
  tabName: {
    type: String,
    required: true,
    default: ''
  },
  tabKey: {
    type: String,
    required: true,
    default: ''
  },
  showClose: {
    type: Boolean,
    required: false,
    default: true
  },
  selfClose: {
    type: Boolean,
    required: false,
    default: false
  }
});
const emit = defineEmits(['close']);
const close = () => {
  if (props.selfClose) {
    emit('close');
    return;
  }
  const router = useRouter();
  if (window.history.state && window.history.state.back) {
    router.back();
  } else {
    router.push(`/${props.tabKey}`);
  }
};

const jumpPage = (tabKey?: string) => {
  let url = `/`;
  if (tabKey?.length > 0) {
    url += `${tabKey}`;
  }
  return url;
};

</script>

<template>
  <div class="breadcrumb-close-nav">
    <div class="breadcrumb">
      <NuxtLink class="breadcrumb-link" :to="jumpPage()">Black Myth: Wukong</NuxtLink>
      <span class="breadcrumb-separator">-</span>
      <NuxtLink class="breadcrumb-link" :to="jumpPage(tabKey)"
        >{{ tabName }}
      </NuxtLink>
    </div>
    <NuxtImg
      v-if="showClose"
      :src="ImageUtil.getLocalImgFromCdn('ic_close_grey')"
      alt="close page"
      class="detail-close"
      @click="close"
    />
  </div>
</template>

<style scoped>
.breadcrumb-close-nav {
  display: flex;
  justify-content: space-between;
}

.detail-close {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
